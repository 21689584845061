export const gatherspotColumns = () => {
  return [
    {
      title: '序号',
      width: 90,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '编号',
      align: 'center',
      width: 120,
      dataIndex: 'no'
    },
    {
      title: '类型',
      align: 'center',
      width: 120,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '集污点名称',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'name'
    },
    {
      title: '详细位置',
      align: 'left',
      width: 245,
      ellipsis: true,
      dataIndex: 'location'
    },
    {
      title: '绑定编号',
      align: 'left',
      width: 150,
      dataIndex: 'monitorPointNo'
    },
    {
      title: '绑定名称',
      align: 'left',
      width: 200,
      dataIndex: 'monitorPointName'
    },
    {
      title: '操作',
      align: 'center',
      width: 200,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}