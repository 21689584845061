<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">集污点绑定信息</h1>
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="编号">
              <a-input v-model="searchParams.no" @change="search" placeholder="请输入编号" style="width: 100px"/>
            </a-form-item>
            <a-form-item label="集污点名称">
              <a-input v-model="searchParams.name" @change="search" placeholder="请输入集污点名称" style="width: 110px"/>
            </a-form-item>
            <a-form-item label="绑定编号">
              <a-input v-model="searchParams.monitorPointNo" @change="search" placeholder="请输入绑定编号"   style="width: 100px"/>
            </a-form-item>
            <a-form-item label="绑定集污点名称">
              <a-input v-model="searchParams.monitorPointName" @change="search" placeholder="请输入绑定集污点名称"    style="width: 110px"/>
            </a-form-item>
            <a-form-item label="集污点">
              <a-radio-group @change="selectType" v-model="searchParams.type">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button :value="spotTypeEnum.DANWENG.value">单翁</a-radio-button>
                <a-radio-button :value="spotTypeEnum.JIWUCHI.value">集污池</a-radio-button>
                <a-radio-button :value="spotTypeEnum.SANGE.value">三格化粪池</a-radio-button>
                <a-radio-button :value="spotTypeEnum.LANI.value">拉泥</a-radio-button>
                <a-radio-button :value="spotTypeEnum.GONGCE.value">公厕</a-radio-button>
                <a-radio-button :value="spotTypeEnum.OTHER.value">其他</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="是否绑定">
              <a-radio-group  @change="search" v-model="searchParams.bind">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="true">是</a-radio-button>
                <a-radio-button value="false">否</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :rowKey="(record) => record.id"
        @change="tableChange"
        :pagination="pagination"
        :columns="columns"
        :dataSource="tableData"
        :scroll="{ x: 1600 }">
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.page * searchParams.size + index + 1 }}
        </template>
        <template slot="name" slot-scope="text, record">
          <div v-if="record.collectType==='WECHAT'">
            <a-icon style="color:#2ba245" type="wechat" theme="filled"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;{{record.name}}
          </div>
          <div v-else-if="record.collectType==='REGISTER'">
            <a-icon style="color:#2b97ff" type="phone" theme="filled"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;{{record.name}}
          </div>
          <div v-else-if="record.collectType==='IMPORT'">
            <a-icon style="color:#5007FF" type="import"/>&nbsp;
            <a-icon type="swap" style="font-size: 14px"/>&nbsp;
            {{record.name}}
          </div>
        </template>
        <template slot="type" slot-scope="text, record">
          <span v-if="record.type===spotTypeEnum.DANWENG.value" color="#00BFFF">
            单翁
          </span>
          <span v-if="record.type===spotTypeEnum.JIWUCHI.value" color="#00CD66">
            集污池
          </span>
          <span v-if="record.type===spotTypeEnum.SANGE.value" color="#00CED1">
            三格化粪池
          </span>
          <span v-if="record.type===spotTypeEnum.LANI.value" color="#00CED1">
            拉泥
          </span>
          <span v-if="record.type===spotTypeEnum.GONGCE.value" color="#00CED1">
            公厕
          </span>
          <span v-if="record.type===spotTypeEnum.OTHER.value" color="#00CED1">
            其他
          </span>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click.stop="handleBinding(record.spotId)">绑定</a>
          <a-divider type="vertical"/>
          <a @click.stop="handleUnBind(record.spotId)">解绑</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="绑定集污点" v-model="bindingModel"
             @ok="handleBindingOk"
             @cancel="closeBindingModal" :maskClosable="false" width="480px">
      <monitoringPointTree @selectedTreeNode="selectedTreeNode"></monitoringPointTree>
    </a-modal>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { gatherspotColumns } from './common/spotBindCommon'
  import PageLayout from '@/components/page/PageLayout'
  import entityCRUD from '../../common/mixins/entityCRUD'
  import moment from 'moment'
  import monitoringPointTree from '../../../components/monitoringPointTree/List'
  import { spotTypeEnum } from '@/constant/spot/spotType'

  export default {
    name: 'GatherSpotList',
    components: { PageLayout, monitoringPointTree },
    mixins: [entityCRUD],
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.gatherSpotBindSosApi,
        expand: false,
        selectedRowKeys: [],
        tempIdList: [],
        userType: '',
        modalTitle: '',
        searchParams: {
          page: '0',
          size: '10',
          no: '',
          name: '',
          type: '',
          checkStatus: 'PASS',
          monitorPointNo: '',
          monitorPointName: '',
          bind: ''
        },
        initColumns: gatherspotColumns(),
        tableData: [],
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}条记录`
        },
        confirmLoading: false,
        bindingModel: false,
        selectSpotInfo: {},
        spotTypeEnum: spotTypeEnum
      }
    },
    created () {
      this.search()
    },
    methods: {
      moment,
      selectType () {
        this.search()
      },
      tableChange (pagination, filters, sorter) {
        this.searchParams.page = pagination.current - 1
        this.searchParams.size = pagination.pageSize
        this.search()
      },
      search () {
        this.$http(this, {
          url: this.entityBaseUrl.search,
          noTips: true,
          loading: 'tableLoading',
          data: this.searchParams,
          success: (data) => {
            this.tableData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.page = data.body.number
          }
        })
      },
      handleBindingOk () {
        if (!this.selectSpotInfo.no && this.selectSpotInfo.name) {
          this.$message.warning('选择的监测点暂无编号')
        } else if (this.selectSpotInfo.no === '' && this.selectSpotInfo.name === '') {
          this.$message.warning('请选择监测点')
        } else {
          this.updateBingSpot('bind')
          this.bindingModel = false
        }
      },
      closeBindingModal () {
        this.bindingModel = false
      },
      handleBinding (id) {
        this.selectSpotInfo.spotId = id
          this.bindingModel = true
      },
      selectedTreeNode (key, node) {
        if (key.length > 0) {
          this.selectSpotInfo.no = node.dataRef.no
          this.selectSpotInfo.name = node.dataRef.title
        } else {
          this.selectSpotInfo.no = ''
          this.selectSpotInfo.name = ''
        }
      },
      updateBingSpot (type) {
        this.$http(this, {
          url: SERVICE_URLS.csgz.gatherSpotBindSosApi.bind,
          data: this.selectSpotInfo,
          noTips: true,
          success: (data) => {
            this.$message.success('绑定成功')
            this.search()
          }
        })
      },
      handleUnBind (spotId) {
        const _this = this
        this.$confirm({
          title: '确认删除操作?',
          content: '此数据将会被删除',
          okText: '删除',
          okType: 'danger',
          cancelText: '取消',
          onOk () {
            _this.$http(_this, {
              url: SERVICE_URLS.csgz.gatherSpotBindSosApi.unbind,
              params: {
                spotId: spotId
              },
              noTips: true,
              success: (data) => {
                _this.$message.success('解绑成功')
                _this.search()
              }
            })
          },
          onCancel () {
          }
        })
      }
    }
  }
</script>
